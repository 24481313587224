<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col lg="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">รายการ Tier</h1>
        </b-col>
        <b-col lg="8" class="text-right">
          <b-row class="no-gutters d-flex justify-content-end">
            <b-button @click="addTier" class="btn-main">สร้าง Tier</b-button>
          </b-row>
        </b-col>
      </CRow>

      <div class="mt-4 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(discountDetail)="data">
                <p class="mb-0 nobreak two-lines">
                  {{ data.item.discountDetail }} %
                </p>
              </template>
              <template v-slot:cell(action)="data">
                <div class="text-center">
                  <router-link :to="'/tier/details/' + data.item.id">
                    <b-button variant="link" class="text-dark px-1 py-0">
                      แก้ไข
                    </b-button>
                  </router-link>
                  <b-button
                    variant="link"
                    @click="deleteData(data.item.id)"
                    class="text-dark px-1 py-0"
                  >
                    ลบ
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row> -->
      </div>
    </div>

    <!-- Modal -->
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";

export default {
  name: "TierIndex",
  components: {
    InputSelect,
    ModalAlertConfirm,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
  },
  data() {
    return {
      modalMessage: "",
      error: "",
      formDelete: {
        id: "",
      },
      fields: [
        {
          key: "name",
          label: "ชื่อ Tier",
          class: "w-100px text-nowrap",
        },
        {
          key: "description",
          label: "ประเภทส่วนลด",
          class: "w-100px text-nowrap",
        },
        {
          key: "discountDetail",
          label: "มูลค่าส่วนลด(%)",
          class: "w-100px text-nowrap",
        },
        { key: "action", label: "", class: "text-center" },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
        Status: [],
        CategoryId: [],
        SortByOrderOrId: 2,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
    };
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    async getList() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/tier/TierList`,
        null,
        this.$headers,
        this.filter
      );

      this.isBusy = false;
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
      }
    },
    async btnDelete() {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/tier/RemoveTier/${this.formDelete.id}`,
        null,
        this.$headers,
        null
      );

      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 2000);
        await this.getList();
      } else {
        this.modalMessage = resData.message;
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
    addTier() {
      if (this.items.length > 10) {
        this.modalMessage = "สามารถเพิ่ม Tier ได้ไม่เกิน 10 รายการ";
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      } else {
        this.$router.push({
          path: `/tier/details/0`,
        });
      }
    },
    deleteData(id) {
      this.modalMessage = "คุณต้องการลบรายการใช่หรือไม่?";
      this.$refs.ModalAlertConfirm.show();
      this.formDelete.id = id;
    },
    async pagination(Page) {
      this.filter.PageNo = Page;
      await this.getList();
      window.scrollTo(0, 0);
    },
    async hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      await this.getList();
    },
    async handleSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        this.filter.PageNo = 1;
        await this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },

    sumPercentDiscount(item){
      let sum = 0;
      this.items.forEach((item) => {
        sum += item.discountPercent;
      });
      return sum / item.length;
    }
  },
};
</script>

<style scoped>
.menuactive {
  color: #ffb300 !important;
}

.line-through {
  text-decoration: line-through;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

::v-deep .square-box {
  padding-top: 5rem !important;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 820px) {
  ::v-deep .modal-body .footer-paginate {
    justify-content: center !important;
  }
}

@media (max-width: 430px) {
  ::v-deep .modal-body .footer-btn {
    margin: 0rem !important;
    padding: 1rem 0rem !important;
  }

  ::v-deep .btn-details-set {
    width: 8rem !important;
    margin: 0 !important;
  }
}
</style>
